import { Box } from "components";

const Hobbies = () => (
  <Box aside title="My Hobbies & Interests" type="hobbies">
    <ul className="list-unstyled">
      <p>
        - Music:&nbsp;
        <span style={{ color: "gray" }}>
          I play guitar, baglama(a Turkish musical instrument) and I can sing 😊
        </span>
      </p>
      <p>- Fitness</p>
      <p>- Swimming</p>
      <p>- Reading</p>
    </ul>
  </Box>
);

export default Hobbies;
