const experiences = [
  {
    id: 0,
    title: "Lead Software Engineer",
    company: "kollex",
    link: "https://www.kollex.de",
    startDate: "June 2021",
    endDate: "",
    location: "Berlin, Germany",
    description: `\
    - Developed high-quality software applications while leading a team of software engineers to meet project objectives.
    - Utilized Agile methodology to develop and implement software solutions with quick turnaround times.
    - Collaborated with stakeholders to effectively manage software development projects and deliverables.
    - Added Unit Tests & E2E Tests and increased the code coverage to almost %70
    - Improved the performance of the mobile app by using Flipper & React DevTools
    `,
    technologies: [
      "React",
      "Typescript",
      "React Native",
      "GraphQL",
      "Tailwind CSS",
      "AWS",
    ],
  },
  {
    id: 1,
    title: "Senior Frontend Engineer(Contract)",
    company: "Shown",
    link: "https://shown.io/en",
    startDate: "Jan 2020",
    endDate: "Nov 2020",
    location: "Brussels, Belgium",
    description: `\
    - Added new features to the React application by following some coding standards
    - Increased the test coverage from %20 to almost %80 by following TDD approach
    - Added some Continuous Integration features into the client app
    - Contributed to the migration work from SCSS to Styled Components
    - Improved the responsiveness of the application on all kind of screen sizes by following the mobile-first approach
    `,
    technologies: [
      "React",
      "Redux",
      "Styled Components",
      "React Testing Library",
    ],
  },
  {
    id: 2,
    title: "Senior Frontend Engineer(Contract)",
    company: "Landvault",
    link: "https://landvault.io",
    startDate: "June 2019",
    endDate: "January 2020",
    location: "London, UK",
    description: `\
    - Refactored some legacy code in the project and implemented modern techniques to make the code more maintainable & scalable
    - Increased the performance of the application by using some standards(caching, code splitting, lazy loading etc.)
    - Implemented a variety of front-end tools to aid the team in streamlining project execution
    `,
    technologies: [
      "React",
      "Redux",
      "Jest & Enzyme",
      "GraphQL",
      "AWS",
      "Docker",
    ],
  },
  {
    id: 3,
    title: "Full Stack Software Developer(Contract)",
    company: "Venzee",
    link: "https://venzee.com",
    startDate: "February 2018",
    endDate: "June 2019",
    location: "Vancouver, Canada",
    description: `\
    - Developed new user-facing features in Angular, React
    - Built reusable code and libraries in the REST API.
    - Ensured the technical feasibility of UI/UX designs.
    - Wrote the according Unit & E2E Tests to ensure maximum quality.
    - Optimized applications for maximum speed and scalability.
    - Assured that all user input is validated before submitting to back-end
    services.
    - Collaborated with other team members and stakeholders.
    `,
    technologies: [
      "React",
      "Angular",
      "Node.js(Loopback Framework)",
      "PostgreSQL",
      "DynamoDB",
      "AWS",
      "Stripe",
      "Elastic Search",
    ],
  },
  {
    id: 4,
    title: "Self Employed(Freelancer)",
    company: "Quaxo",
    link: "https://quaxo.net",
    startDate: "2017",
    endDate: "2019",
    location: "Mugla, Turkey",
    description:
      "I created various web & mobile apps for different startups as the founder of the Quaxo software development agency. I wire-framed, designed and implemented all of the apps from scratch. I had regular meetings with the clients to understand the requirements and give & get feedback.",
    technologies: [
      "React",
      "Typescript",
      "React Native",
      "Node.js",
      "MongoDB",
      "GraphQL",
      "Docker",
      "Firebase",
    ],
  },
  {
    id: 5,
    title: "Mobile Application Development Internship",
    company: "Trendyol",
    link: "https://www.trendyol.com",
    startDate: "June 2017",
    endDate: "August 2017",
    location: "Istanbul, Turkey",
    description:
      "I was in a long term internship program at Trendyol. I worked on the native Android and iOS apps as a mobile application developer. I worked on the main shopping and cart screens on both platforms by using Java & Objective-C. I learned about the native APIs and improved UI & some functionalities. We were using agile framework for the planning, managing and executing of the work.",
  },
  {
    id: 6,
    title: "Full Stack Development Internship",
    company: "OBSS",
    link: "https://obss.tech/en",
    startDate: "July 2016",
    endDate: "August 2016",
    location: "Istanbul, Turkey",
    description:
      "I was in a long term internship program at OBSS. The internship was in the form of competition. I finished the competition in the second place. I created a full stack application with Java, Spring Boot, Angular, MongoDB, Elastic Search.",
  },
  {
    id: 7,
    title: "Full Stack Developer",
    company: "V-count",
    link: "https://v-count.com",
    startDate: "February 2016",
    endDate: "June 2016",
    location: "Ankara, Turkey",
    description:
      "https://v-count.comI worked on both Front-end, Back-end parts of the V-count platform. Company was following the mono-repo approach. Server-side rendering was implemented for the client application. I fixed bugs on both Front-end & Back-end, added some new features and worked on the database part.",
    technologies: [
      "A custom PHP MVC Framework",
      "Javascript",
      "MySQL",
      "Bootstrap 3",
    ],
  },
];

export default experiences;
