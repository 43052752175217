import styled from "styled-components";

import { Box } from "components";
import removeBlankLines from "utils/removeBlankLines";
import experiences from "data/experiences";

const WorkExperience = () => (
  <Styled>
    <Box title="Work Experience" type="experience">
      {experiences.map(
        ({
          id,
          title,
          company,
          description,
          startDate,
          endDate,
          technologies,
          link,
        }) => (
          <div className="item" key={id}>
            <h3 className="title">
              {title} -&nbsp;
              <span className="place">
                <a href={link} target="_blank" rel="noreferrer">
                  {company}&nbsp;
                </a>
              </span>
              <span className="year">
                ({startDate} – {endDate})
              </span>
            </h3>
            <p className="description">{removeBlankLines(description)}</p>
            {technologies && (
              <>
                <br />
                <p>
                  <b>Tech Stack:</b> {technologies.join(", ")}
                </p>
              </>
            )}
          </div>
        )
      )}
      <a
        className="btn btn-cta-secondary"
        href="https://www.linkedin.com/in/onurdmrts"
        target="_blank"
        rel="noreferrer"
      >
        My Linkedin Profile <i className="fa fa-chevron-right" />
      </a>
    </Box>
  </Styled>
);

const Styled = styled.div`
  .experience {
    .item {
      margin-bottom: 4rem;

      .title {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 5px;
        line-height: 1.5;
        .place {
          color: #999;
          font-weight: normal;
          a {
            color: #999;
            &:hover {
              color: #666666;
            }
          }
        }
        .year {
          color: #999;
          font-weight: normal;
        }
      }

      .description {
        white-space: pre-wrap;
        margin-bottom: -0.5rem;
      }
    }
  }
`;

export default WorkExperience;
