import Theme from "./theme";

const Footer = () => {
  return (
    <Theme>
      <footer className="footer">
        <div className="container text-center">
          <small className="copyright">
            Created with <i className="fa fa-heart"></i> by{" "}
            <a
              href="https://www.linkedin.com/in/onurdmrts"
              target="_blank"
              rel="noreferrer"
            >
              Onur Demirtas
            </a>
            . Thanks for stopping by :)
          </small>
        </div>
      </footer>
    </Theme>
  );
};

export default Footer;
