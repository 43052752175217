import styled from "styled-components";

import { Box } from "components";

const Languages = () => (
  <Styled>
    <Box aside title="Languages" type="languages">
      <ul className="list-unstyled">
        <li className="item">
          <span className="title">
            <strong>English: </strong>
          </span>
          <span className="level">
            Fluent <br className="visible-xs" />
            <i className="fa fa-star" /> <i className="fa fa-star" />
            <i className="fa fa-star" /> <i className="fa fa-star" />
            <i className="fa fa-star" />
          </span>
        </li>
        <li className="item">
          <span className="title">
            <strong>Turkish: </strong>
          </span>
          <span className="level">
            Native Speaker <br className="visible-xs" />
            <i className="fa fa-star" /> <i className="fa fa-star" />
            <i className="fa fa-star" /> <i className="fa fa-star" />
            <i className="fa fa-star" />
          </span>
        </li>
      </ul>
    </Box>
  </Styled>
);

const Styled = styled.div`
  .languages {
    .item {
      margin-bottom: 15px;
      .title {
        color: #778492;
      }
      .level {
        color: #999;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .fa {
        color: #79d19a;
      }
    }
  }
`;

export default Languages;
