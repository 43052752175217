import { Box } from "components";

const Skills = () => (
  <Box aside title="Skills" type="skills">
    <div>
      <p className="intro">These are my top skills ❤️</p>
      <div className="skillset">
        <div className="item">
          <h3 className="level-title">
            React &amp; Redux
            <span
              className="level-label"
              data-toggle="tooltip"
              data-placement="left"
              data-animation="true"
              title="I love React <3 "
            >
              Expert
            </span>
          </h3>
          <div className="level-bar">
            <div className="level-bar-inner" data-level="96%"></div>
          </div>
        </div>
        <div className="item">
          <h3 className="level-title">
            React Native
            <span
              className="level-label"
              data-toggle="tooltip"
              data-placement="left"
              data-animation="true"
              title="I love React Native <3 "
            >
              Expert
            </span>
          </h3>
          <div className="level-bar">
            <div className="level-bar-inner" data-level="96%"></div>
          </div>
        </div>
        <div className="item">
          <h3 className="level-title">
            Typescript<span className="level-label">Expert</span>
          </h3>
          <div className="level-bar">
            <div className="level-bar-inner" data-level="96%"></div>
          </div>
        </div>
        <div className="item">
          <h3 className="level-title">
            Vue.js<span className="level-label">Intermediate</span>
          </h3>
          <div className="level-bar">
            <div className="level-bar-inner" data-level="70%"></div>
          </div>
        </div>
        <div className="item">
          <h3 className="level-title">
            Node.js<span className="level-label">Intermediate</span>
          </h3>
          <div className="level-bar">
            <div className="level-bar-inner" data-level="70%"></div>
          </div>
        </div>
        <p>
          <a
            className="more-link"
            href="https://www.linkedin.com/in/onurdmrts"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa fa-linkedin-square" /> More on Linkedin
          </a>
        </p>
      </div>
    </div>
  </Box>
);

export default Skills;
