const mobile = "768px";
const tablet = "992px";
const desktop = "1200px";

export default {
  mobile: `@media (max-width: ${mobile})`,
  tablet: `@media (min-width: ${mobile}) and (max-width: ${tablet})`,
  desktop: `@media (min-width: ${tablet}) and (max-width: ${desktop})`,
  largeScreen: `@media (min-width: ${desktop})`
};
