import styled from "styled-components";

export default styled.div`
  .section {
    margin-bottom: 30px;
    .section-inner {
      background: #fff;
      padding: 30px;
    }
    .heading {
      margin-top: 0;
      margin-bottom: 30px;
      color: #545e69;
      font-size: 24px;
    }
    .content .more-link .fa {
      margin-right: 5px;
      font-size: 14px;
    }
  }
`;
