import styled from "styled-components";

export default styled.div`
  .header {
    padding: 30px 0;
    background: #f5f5f5;
    border-top: 10px solid #778492;
    .btn {
      margin-top: 60px;
      font-weight: bold;
    }
    .profile-image {
      margin-right: 30px;
    }
    .profile-content {
      .name {
        color: #49515a;
        font-size: 38px;
        margin-bottom: 5px;
        margin-top: 30px;
      }
      .desc {
        color: #778492;
        font-family: "Lato", arial, sans-serif;
        font-weight: 400;
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 15px;
      }
      .social a {
        background: #b0b7bf;
        width: 36px;
        height: 36px;
        display: inline-block;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        color: #fff;
        text-align: center;
        &:hover {
          background: #778492;
        }
        .fa {
          font-size: 20px;
          padding-top: 8px;
        }
      }
    }
  }
`;
