/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box } from "components";
import Theme from "./theme";
import About from "./about";
import Projects from "./projects";
import WorkExperience from "./work_experience";
import Info from "./info";
import Skills from "./skills";
import Hobbies from "./hobbies";
import Languages from "./languages";
import Quote from "./quote";
import Education from "./education";

const Body = () => {
  return (
    <Theme>
      <div className="container sections-wrapper">
        <div className="row">
          <div className="primary col-md-8 col-sm-12 col-xs-12">
            <About />
            <Projects />
            <WorkExperience />
          </div>
          <div className="secondary col-md-4 col-sm-12 col-xs-12">
            <Info />
            <Skills />
            <Education />
            <Quote />
            <Languages />

            <Box aside title="What do I focus on?" type="list conferences">
              <ul className="list-unstyled">
                <li>
                  <i
                    style={{ fontSize: "12px", marginLeft: "-2px" }}
                    className="fa fa-laptop"
                  />
                  <a className="link">Front-end Development</a>
                </li>
                <li>
                  <i
                    style={{ fontSize: "15px", marginRight: "9px" }}
                    className="fa fa-mobile"
                  />
                  <a className="link">Cross Platform Mobile Development</a>
                </li>
                <li>
                  <i
                    style={{ fontSize: "15px", marginRight: "9px" }}
                    className="fa fa-mobile"
                  />
                  <a className="link">Web &amp; Mobile UI, UX Design</a>
                </li>
              </ul>
            </Box>

            <Hobbies />
          </div>
        </div>
      </div>
    </Theme>
  );
};

export default Body;
