import { Box } from "components";

const Info = () => (
  <Box aside type="info">
    <ul className="list-unstyled">
      <li>
        <i style={{ fontSize: "20px" }} className="fa fa-map-marker" />
        <span className="sr-only">Location:</span>&nbsp;Onur Demirtas, Berlin -
        Germany
      </li>
      <li>
        <i style={{ fontSize: "15px" }} className="fa fa-envelope-o" />
        <span className="sr-only">Email:</span>
        <a href="mailto:onurdemirtas91@gmail.com">onurdemirtas91@gmail.com</a>
      </li>
      <li>
        <i className="fa fa-heart" />
        <span className="sr-only">Blog:</span>Click{" "}
        <a href="https://blog.onurdemirtas.com">here</a> for my awesome blog
      </li>
    </ul>
  </Box>
);

export default Info;
