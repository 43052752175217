import styled from "styled-components";

import { Box } from "components";

const Quote = () => (
  <Styled>
    <Box aside title="A quote" type="testimonials">
      <div className="item">
        <blockquote className="quote">
          <p>
            <i className="fa fa-quote-left" />
            Do not let circumstances control you. You control your
            circumstances.{" "}
          </p>
        </blockquote>
        <p className="source">
          <span className="name">Jackie Chan</span>
          <br />
          <span className="title">Actor &amp; Producer</span>
        </p>
      </div>
    </Box>
  </Styled>
);

const Styled = styled.div`
  .testimonials {
    .item {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      .quote {
        color: #666;
        font-size: 16px;
        border-left-color: #9fdeb7;
        margin-bottom: 15px;
        .fa {
          color: #79d19a;
          margin-right: 15px;
        }
      }
      .source {
        font-size: 14px;
        padding-left: 30px;
        font-weight: 500;
        .name {
          color: #939ea9;
          font-weight: 600;
        }
        .title {
          color: #999;
        }
      }
    }
  }
`;

export default Quote;
