import styled from "styled-components";

export default styled.div`
  /*
  .projects .item {
    margin-bottom: 30px;
    .title {
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 5px;
      line-height: 1.5;
      a {
        color: #778492;
        &:hover {
          color: #5f6b77;
        }
      }
    }
  }
  */

  .credits {
    ul li {
      margin-bottom: 10px;
      a {
        color: #778492;
        &:hover {
          color: #49515a;
        }
      }
      .fa {
        margin-right: 5px;
      }
    }
    .btn {
      margin-bottom: 15px;
    }
  }

  .education .item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      font-size: 16px;
      margin-top: 0;
    }
    .university {
      font-family: "Lato", arial, sans-serif;
      font-size: 13px;
      color: #999;
      font-weight: 600;
      padding-left: 25px;
      .year {
        color: #b0b7bf;
        font-weight: 500;
      }
    }
  }

  .sections-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .info {
    .fa {
      margin-right: 15px;
      color: #ccd1d6;
      .fa-envelope-o {
        font-size: 14px;
      }
    }
    ul {
      margin-bottom: 0;
    }
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .skills {
    .intro {
      margin-bottom: 30px;
    }
    .skillset {
      .item {
        margin-bottom: 30px;
      }
      .level-title {
        font-size: 16px;
        position: relative;
        margin-top: 0;
        margin-bottom: 10;
        .level-label {
          color: #ccd1d6;
          font-size: 14px;
          font-weight: 400;
          font-family: "Lato", arial, sans-serif;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .level-bar {
        height: 15px;
        background: #e8e8e8;
      }
      .level-bar-inner {
        height: 15px;
        background: #66cb8c;
      }
    }
  }
`;
