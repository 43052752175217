const projects = [
  {
    id: 0,
    title: "Film Tavsiyeleri",
    description:
      "Film Tavsiyeleri is a mobile application where users can see movie recommendations based on the movie categories they choose.",
    technologies: ["React & React Native", "Typescript", "Strapi(Node.js)"],
    link: "http://bit.ly/2VXStru",
    img: "assets/images/projects/film_tavsiyeleri.png",
  },
  {
    id: 1,
    title: "Devzilla",
    description:
      "It is a platform where developers, designers and the people who are part of the tech industry can test their skills on the go.",
    technologies: ["Ionic Framework", "Typescript", "Node.js"],
    link: "https://bit.ly/3GjJdC0",
    img: "assets/images/projects/devzilla.webp",
  },
];

export default projects;
