import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import Theme from "./theme";

type BoxProps = {
  type: string;
  title?: string;
  aside?: boolean;
};

const Box: React.FC<PropsWithChildren<BoxProps>> = ({
  title,
  children,
  type,
  aside,
}) => {
  const Wrapper = aside ? "aside" : "section";

  const wrapperClass = classNames("section", type);

  return (
    <Theme>
      <Wrapper className={wrapperClass}>
        <div className="section-inner">
          {title && <h2 className="heading">{title}</h2>}
          <div className="content">{children}</div>
        </div>
      </Wrapper>
    </Theme>
  );
};

export default Box;
