/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import Theme from "./theme";

const Header = () => {
  return (
    <Theme>
      <header className="header">
        <div className="container">
          <img
            className="profile-image img-responsive pull-left"
            src="assets/images/profile.png"
            alt="Onur Demirtaş"
          />
          <div className="profile-content pull-left">
            <h1 className="name">Onur Demirtas</h1>
            <h2 className="desc">Senior Software Engineer, UI & UX Designer</h2>
            <ul className="social list-inline">
              <li>
                <a href="https://www.linkedin.com/in/onurdmrts" target="_blank">
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="https://github.com/svmszcck" target="_blank">
                  <i className="fa fa-github"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/svmszcck" target="_blank">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://blog.onurdemirtas.com" target="_blank">
                  <i className="fa fa-rss"></i>
                </a>
              </li>
            </ul>
          </div>
          <a
            className="btn btn-cta-primary pull-right"
            href="mailto:onurdemirtas91@gmail.com"
          >
            <i className="fa fa-paper-plane"></i> Contact Me
          </a>
        </div>
      </header>
    </Theme>
  );
};

export default Header;
