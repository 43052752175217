import styled from "styled-components";

export default styled.div`
  .footer {
    background: #32383e;
    color: #fff;
    padding: 10px 0;
    .copyright {
      line-height: 1.6;
      color: #a1aab4;
      font-size: 14px;
    }
    a {
      color: #fff;
    }
    .fa-heart {
      color: #fb866a;
    }
  }
`;
