import { Box } from "components";

const About = () => (
  <Box title="About Me" type="about">
    <p>
      I worked on various web projects including Front-end & Back-end & Database
      parts and also cross platform mobile apps(React Native & Ionic Framework).
      From my job experiences and my own side-projects I have learned valuable
      professional skills such as web & mobile app development and algorithmic
      thinking. I am a results-oriented person and I am truly passionate about
      coding. I am currently focusing on Front-end & Cross Platform Mobile
      Development.
    </p>
    <p>
      My favourite languages and technologies:
      <b> React & Redux, Typescript, React Native, Vue.js and Node.js</b>
    </p>
    <p>I studied Computer Science at Middle East Technical University..</p>
    <p>
      My CV:&nbsp;
      <a
        className="link"
        href="https://bit.ly/2Uv0aiY"
        target="_blank"
        rel="noreferrer"
      >
        https://bit.ly/2Uv0aiY
      </a>
    </p>
    <p>
      My Apps:&nbsp;
      <a
        className="link"
        href="https://bit.ly/2HAS05H"
        target="_blank"
        rel="noreferrer"
      >
        https://bit.ly/2HAS05H
      </a>
    </p>
    <p>
      Reference Letter:&nbsp;
      <a
        className="link"
        href="https://bit.ly/34Rm0mF"
        target="_blank"
        rel="noreferrer"
      >
        http://bit.ly/34Rm0mF
      </a>
    </p>
    <p>
      My Github:&nbsp;
      <a
        className="link"
        href="https://github.com/svmszcck"
        target="_blank"
        rel="noreferrer"
      >
        https://github.com/svmszcck
      </a>
    </p>
    <p>
      My Blog:&nbsp;
      <a
        className="link"
        href="https://blog.onurdemirtas.com"
        target="_blank"
        rel="noreferrer"
      >
        https://blog.onurdemirtas.com
      </a>
    </p>
  </Box>
);

export default About;
