import { Box } from "components";

const Education = () => (
  <Box aside title="Education" type="education">
    <div className="item">
      <h3 className="title">
        <i className="fa fa-graduation-cap" /> BSc Computer Science
      </h3>
      <h4 className="university">Middle East Technical University</h4>
    </div>
    <div className="item">
      <h3 className="title">
        <i className="fa fa-graduation-cap" /> Medical School (Drop Out)
      </h3>
      <h4 className="university">Hacettepe University Medical School</h4>
    </div>
    <div className="item">
      <h3 className="title">
        <i className="fa fa-graduation-cap" /> High School
      </h3>
      <h4 className="university">Aydın Science High School</h4>
    </div>
  </Box>
);

export default Education;
