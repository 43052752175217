/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "styled-components";

import { Box } from "components";
import Devices from "devices";
import projects from "data/projects";

const Projects = () => (
  <Styled>
    <Box title="Latest Projects" type="latest">
      <div className="content">
        <div className="item featured text-center">
          <h3 className="title">
            <a
              href="https://bit.ly/remotejobsapp"
              target="_blank"
              rel="noreferrer"
            >
              Remote Jobs
            </a>
          </h3>
          <p className="summary">
            Browse job opportunities from the most remote-friendly companies in
            the world.
          </p>
          <div className="featured-image">
            <img
              className="img-responsive project-image"
              src="assets/images/projects/remote-jobs.png"
              alt="project name"
            />

            <div className="ribbon">
              <div className="text">New</div>
            </div>
          </div>

          <div className="desc text-left">
            <p>
              Remote Jobs is a platform to find and list remote-only jobs world
              wide. Its' user friendly interface allows users to browse job
              opportunities from the most remote-friendly companies in the
              world. Companies can create remote job posts and find the right
              candidates easily. "Remote Jobs" platform matches the most
              suitable candidates with the most suitable remote job adverts.
            </p>
            <p>
              <a
                className="more-link"
                href="https://bit.ly/remotejobsapp"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-external-link"></i> Browse the web site
              </a>
            </p>
            <p>Remote Jobs is also available on the Google Play Store:</p>
            <p>
              <a
                className="more-link"
                href="http://bit.ly/2NnzOy5"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-external-link"></i> Check the mobile app
              </a>
            </p>
            <p>
              <b>Used Technologies:</b> React & Redux, Styled Components, React
              Native, Node.js, Express.js
            </p>
          </div>
        </div>

        <hr className="divider" />

        {projects.map(({ id, title, description, technologies, img, link }) => (
          <div className="item row" key={id}>
            <a className="col-md-4 col-sm-4 col-xs-12">
              <img
                className="img-responsive project-image"
                src={img}
                alt={title}
              />
            </a>
            <div className="desc col-md-8 col-sm-8 col-xs-12">
              <h3 className="title">
                <a href={link} target="_blank" rel="noreferrer">
                  {title}
                </a>
                <span className="label label-theme">Mobile</span>
              </h3>
              <p>{description}</p>
              <p>
                <b>Used Technologies:</b> {technologies.join(", ")}
              </p>
              <p>
                <a
                  className="more-link"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-external-link"></i> Look at the
                  application
                </a>
              </p>
            </div>
          </div>
        ))}
      </div>
    </Box>
  </Styled>
);

const Styled = styled.div`
  .latest {
    .item {
      margin-bottom: 30px;

      .title {
        display: flex;
        align-items: center;

        font-size: 18px;
        margin-top: 0;

        .label {
          margin-left: 5px;
          font-size: 12px;
        }
        a {
          color: #778492;
          &:hover {
            color: #5f6b77;
          }
        }
      }
      .project-image:hover {
        -webkit-opacity: 0.8;
        -moz-opacity: 0.8;
        opacity: 0.8;
      }
    }
    .divider {
      margin-bottom: 60px;
    }

    .featured {
      margin-bottom: 60px;

      .title {
        justify-content: center;
        margin-bottom: 5px;
        font-size: 20px;

        ${Devices.mobile} {
          text-align: left;
        }
      }
      .summary {
        margin-bottom: 30px;
        color: #778492;
      }
      img {
        margin-bottom: 30px;
      }
      .desc {
        margin-bottom: 30px;
      }
    }
    .featured-image {
      position: relative;
      .text {
        background: #3aaa64;
        color: #fff;
      }
      .ribbon {
        position: absolute;
        top: -4px;
        right: -4px;
        width: 110px;
        height: 110px;
        overflow: hidden;
        .text {
          font-family: "Montserrat", sans-serif;
          position: relative;
          left: -8px;
          top: 18px;
          width: 158px;
          padding: 10px 10px;
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
          color: #fff;
          background-color: #479fc8;
          -webkit-transform: rotate(45deg) translate3d(0, 0, 0);
          -moz-transform: rotate(45deg) translate3d(0, 0, 0);
          -ms-transform: rotate(45deg) translate3d(0, 0, 0);
          -o-transform: rotate(45deg) translate3d(0, 0, 0);
          &:before {
            left: 0;
          }
          &:after {
            right: 0;
          }
          &:before,
          &:after {
            content: "";
            position: absolute;
            bottom: -5px;
            border-top: 5px solid #276582;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }
        }
      }
    }
  }
`;

export default Projects;
