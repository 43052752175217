import Theme from "./theme";

import { Header, Footer, Body } from "components";

function App() {
  return (
    <div className="App">
      <Theme />
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default App;
